// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {BOei56hVx: {hover: true}, CEbXlP13V: {hover: true}};

const cycleOrder = ["BOei56hVx", "CEbXlP13V"];

const serializationHash = "framer-2SvdZ"

const variantClassNames = {BOei56hVx: "framer-v-l5cwxr", CEbXlP13V: "framer-v-cwr45y"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "BOei56hVx", "Variant 2": "CEbXlP13V"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "BOei56hVx"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "BOei56hVx", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-l5cwxr", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"BOei56hVx"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"BOei56hVx-hover": {"data-framer-name": undefined}, "CEbXlP13V-hover": {"data-framer-name": undefined}, CEbXlP13V: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}><motion.div className={"framer-e2koe7"} layoutDependency={layoutDependency} layoutId={"wdhGq4oEi"} style={{backgroundColor: "var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23))", rotate: 0}} variants={{"BOei56hVx-hover": {backgroundColor: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(252, 108, 17))"}, "CEbXlP13V-hover": {backgroundColor: "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(42, 42, 43))", rotate: -36}, CEbXlP13V: {rotate: -45}}}/><motion.div className={"framer-1jzuci5"} layoutDependency={layoutDependency} layoutId={"NY7AkRVrh"} style={{backgroundColor: "var(--token-228b5fa0-96b3-4b15-8bc3-528c090ea1ba, rgb(23, 23, 23))", rotate: 0}} variants={{"BOei56hVx-hover": {backgroundColor: "var(--token-9065ca1f-1dfb-4011-af90-7c0570697ffc, rgb(252, 108, 17))"}, "CEbXlP13V-hover": {backgroundColor: "var(--token-f6bc43b4-a35d-48d9-8f21-b0b1c50dca05, rgb(42, 42, 43))", rotate: 36}, CEbXlP13V: {rotate: 45}}}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-2SvdZ.framer-1ysr7t9, .framer-2SvdZ .framer-1ysr7t9 { display: block; }", ".framer-2SvdZ.framer-l5cwxr { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 4px; height: 18px; justify-content: center; overflow: hidden; padding: 2px; position: relative; width: 18px; }", ".framer-2SvdZ .framer-e2koe7, .framer-2SvdZ .framer-1jzuci5 { flex: none; height: 2px; overflow: hidden; position: relative; width: 14px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2SvdZ.framer-l5cwxr { gap: 0px; } .framer-2SvdZ.framer-l5cwxr > * { margin: 0px; margin-bottom: calc(4px / 2); margin-top: calc(4px / 2); } .framer-2SvdZ.framer-l5cwxr > :first-child { margin-top: 0px; } .framer-2SvdZ.framer-l5cwxr > :last-child { margin-bottom: 0px; } }", ".framer-2SvdZ.framer-v-cwr45y .framer-e2koe7, .framer-2SvdZ.framer-v-cwr45y .framer-1jzuci5 { left: calc(50.00000000000002% - 14px / 2); position: absolute; top: calc(50.00000000000002% - 2px / 2); z-index: 1; }", ".framer-2SvdZ.framer-v-l5cwxr.hover.framer-l5cwxr { gap: 6px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-2SvdZ.framer-v-l5cwxr.hover.framer-l5cwxr { gap: 0px; } .framer-2SvdZ.framer-v-l5cwxr.hover.framer-l5cwxr > * { margin: 0px; margin-bottom: calc(6px / 2); margin-top: calc(6px / 2); } .framer-2SvdZ.framer-v-l5cwxr.hover.framer-l5cwxr > :first-child { margin-top: 0px; } .framer-2SvdZ.framer-v-l5cwxr.hover.framer-l5cwxr > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 18
 * @framerIntrinsicWidth 18
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"CEbXlP13V":{"layout":["fixed","fixed"]},"mKvUMu5Tt":{"layout":["fixed","fixed"]},"E0NkPFCza":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruSrgg0hkn: React.ComponentType<Props> = withCSS(Component, css, "framer-2SvdZ") as typeof Component;
export default FrameruSrgg0hkn;

FrameruSrgg0hkn.displayName = "Menu/Icon";

FrameruSrgg0hkn.defaultProps = {height: 18, width: 18};

addPropertyControls(FrameruSrgg0hkn, {variant: {options: ["BOei56hVx", "CEbXlP13V"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FrameruSrgg0hkn, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})